import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';
import filtersKeys from 'constants/filters';
import DateHelpers from 'helpers/Date';
import CONFIG from 'config';

interface Entity {
  isFetch: boolean;
  isFetched?: boolean;
  sort: any;
  items: any[];
  totalItems: number;
  offset: 0;
}

interface State {
  payments: Entity;
  payouts: Entity;
  massPayouts: Entity;
  reports: Entity;
  teamUsers: Entity;
  users: Entity;
  teamActivities: Entity;
  invoices: Entity;
  rcsBlackWhite: Entity;
  rcsFraud: Entity;
  paymentOperationReference: Entity;
  chargebacks: Entity;
  subscriptionPayments: Entity;
  apsIcon: Entity;
  remittanceRecipientAccounts: Entity;
  remittancePayments: Entity;
  massRemittancePayments: Entity;
  remittancePaymentBatch: Entity;
  financialFile: Entity;
  bankAccount: Entity;
  balanceTransfer: Entity;
  settlementRequest: Entity;
}

const initialState: State = {
  payments: {
    isFetch: false,
    sort: {
      field: filtersKeys.date,
      order: 'desc',
    },
    items: [],
    totalItems: 0,
    offset: 0,
  },
  payouts: {
    isFetch: false,
    sort: {
      field: filtersKeys.date,
      order: 'desc',
    },
    items: [],
    totalItems: 0,
    offset: 0,
  },
  massPayouts: {
    isFetch: false,
    sort: {
      field: filtersKeys.date,
      order: 'desc',
    },
    items: [],
    totalItems: 0,
    offset: 0,
  },
  teamUsers: {
    isFetch: false,
    sort: {
      field: filtersKeys.date,
      order: 'asc',
    },
    items: [],
    totalItems: 0,
    offset: 0,
  },
  users: {
    isFetch: false,
    sort: {
      field: filtersKeys.date,
      order: 'asc',
    },
    items: [],
    totalItems: 0,
    offset: 0,
  },
  teamActivities: {
    isFetch: false,
    sort: {
      field: filtersKeys.date,
      order: 'desc',
    },
    items: [],
    totalItems: 0,
    offset: 0,
  },
  reports: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  invoices: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  rcsBlackWhite: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  rcsFraud: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  paymentOperationReference: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  subscriptionPayments: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  chargebacks: {
    isFetch: false,
    sort: {
      field: filtersKeys.respondBy,
      order: 'asc',
    },
    items: [],
    totalItems: 0,
    offset: 0,
  },
  apsIcon: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  remittanceRecipientAccounts: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  remittancePayments: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  massRemittancePayments: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  remittancePaymentBatch: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  financialFile: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  bankAccount: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  balanceTransfer: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
  settlementRequest: {
    isFetch: false,
    sort: {},
    items: [],
    totalItems: 0,
    offset: 0,
  },
};

const data = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.CHANGE_DATA_OFFSET:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          offset: payload.offset,
        },
      };
    case ACTIONS.CHANGE_DATA_SORTING:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          sort: payload.sort,
        },
      };
    case ACTIONS.CHANGE_DATA_FORCE_UPDATE:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          forceUpdate: payload.value,
        },
      };
    case ACTIONS.SAVE_DATA_STATE:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          ...payload.state,
        },
      };
    case ACTIONS.FETCH_DATA_PROGRESS:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          isFetch: true,
        },
      };
    case ACTIONS.FETCH_DATA_UPLOAD_SUCCESS:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          isFetch: false,
          items: [...state[payload.name].items, ...payload.items],
          totalItems:
            CONFIG.REQUEST_ITEMS_LIMIT > payload.items.length
              ? payload.items.length + state[payload.name].items.length
              : payload.totalRows,
        },
      };
    case ACTIONS.FETCH_DATA_SUCCESS:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          isFetch: false,
          isFetched: true,
          items: payload.items,
          totalItems:
            CONFIG.REQUEST_ITEMS_LIMIT > payload.items.length
              ? payload.items.length
              : payload.totalRows,
        },
      };
    case ACTIONS.FETCH_DATA_FAIL:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          isFetch: false,
        },
      };
    case ACTIONS.RESET_PART_OF_DATA:
      return {
        ...state,
        [payload.name]: { ...initialState[payload.name], isFetch: true },
      };
    case ACTIONS.UPDATE_DATA_ITEM:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          items: state[payload.name].items.map((item) => {
            if (item[payload.key] === payload.data[payload.key]) {
              return { ...item, ...payload.data };
            }
            return item;
          }),
        },
      };
    case ACTIONS.UPDATE_DATA_ITEM_PROPERTIES:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          items: state[payload.name].items.map((item) => {
            if (item[payload.key] === payload.data[payload.key]) {
              payload.propsToUpdate.forEach((propToUpdate) => {
                item[propToUpdate] = payload.data[propToUpdate];
              });
              return { ...item };
            }
            return item;
          }),
        },
      };
    case ACTIONS.DELETE_DATA_ITEM:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          items: state[payload.name].items.filter(
            (item) => item[payload.key] !== payload.keyValue
          ),
          totalItems: state[payload.name].totalItems - 1,
        },
      };
    case ACTIONS.ADD_DATA_ITEM: {
      let items = [payload.data, ...state[payload.name].items];
      if (state[payload.name].sort.order) {
        const sortField = state[payload.name].sort.field;
        items = items.sort((a, b) => {
          const fieldA = +DateHelpers.createDate(
            a[sortField],
            'datetime'
          ).toDate();
          const fieldB = +DateHelpers.createDate(
            b[sortField],
            'datetime'
          ).toDate();

          return state[payload.name].sort.order === 'asc'
            ? fieldA - fieldB
            : fieldB - fieldA;
        });
      }
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          items,
          totalItems: state[payload.name].totalItems + 1,
        },
      };
    }
    case ACTIONS.ADD_DATA_ITEMS:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          items: [...payload.list, ...state[payload.name].items],
          totalItems: state[payload.name].totalItems + payload.list.length,
        },
      };
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default data;
