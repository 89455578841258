import types from 'components/ui/dataBlock/types';

/**
 * Класс преобразования данных из DWH в наш формат для компонента DataBlock
 */
class PaymentDataMapper {
  static getOperationInfo(operationData) {
    const { payment_provider_info, callback, payment_method_type, iconUrl } =
      operationData;

    return {
      operationInfo: [
        {
          key: 'payments.data.operationType',
          value: operationData.operationType,
        },
        {
          key: 'payments.data.operationStatus',
          value: operationData.operationStatus,
          type: types.status,
        },
        {
          key: 'payments.data.amount',
          value: `${operationData.operationAmount}\u00A0${operationData.operationCurrency}`,
        },
        {
          key: 'payments.data.channelAmount',
          value: `${operationData.channel_amount}\u00A0${operationData.channel_currency}`,
        },
        {
          key: 'payments.data.billingAmount',
          value: ` ${operationData.billing_amount}\u00A0${operationData.billing_currency}`,
        },
        {
          key: 'payments.data.billingConversionRate',
          value: operationData.billing_conversion_rate,
        },
        {
          key: 'payments.data.amountWithoutTax',
          value: operationData.amount_without_tax
            ? `${operationData.amount_without_tax} ${operationData.vat_operation_currency}`
            : operationData.amount_without_tax,
          isVisible:
            operationData.amount_without_tax !== undefined,
        },
        {
          key: 'payments.data.vatResidency',
          value: operationData.vat_operation_residency,
        },
        {
          key: 'payments.data.vatRate',
          value: operationData.vat_rate,
        },
        {
          key: 'payments.data.vatAmount',
          value: operationData.vat_operation_amount
            ? `${operationData.vat_operation_amount} ${operationData.vat_operation_currency}`
            : operationData.vat_operation_amount,
          isVisible:
            operationData.vat_operation_amount !== undefined,
        },
        {
          key: 'payments.data.authCode',
          value: payment_provider_info.authCode,
        },
        { key: 'payments.data.moto', value: operationData.moto },
        {
          key: 'payments.data.created',
          value: operationData.creationDate,
        },
        {
          key: 'payments.data.updated',
          value: operationData.updatedDate,
        },
        {
          key: 'payments.data.operationFee',
          value: operationData.feeAmount,
        },
        {
          key: 'payments.data.currencyFee',
          value: operationData.feeCurrency,
        },
        {
          key: 'payments.data.providerFee',
          value: operationData.msc_amount,
        },
        {
          key: 'payments.data.providerFeeCurrency',
          value: operationData.msc_currency,
        },
        {
          key: 'payments.data.operationHold',
          value: operationData.operation_hold,
        },
        {
          key: 'payments.data.recurringId',
          value: operationData.recurring_info.recurringRegistrationId,
        },
      ],

      paymentProviderInfo: [
        {
          key: 'payments.data.paymentProviderName',
          value: payment_provider_info.paymentProviderName,
        },
        {
          key: 'payments.data.providerID',
          value: payment_provider_info.endpointPaymentProviderId,
        },
        {
          key: 'payments.data.providerPaymentID',
          value: payment_provider_info.acquirerId,
        },
        {
          key: 'payments.data.authCode',
          value: payment_provider_info.authCode,
        },
        {
          key: 'payments.data.paymentProviderDate',
          value: payment_provider_info.paymentProviderDate,
        },
        {
          key: 'payments.data.providerreasoncode',
          value: payment_provider_info.paymentProviderCode,
        },
        {
          key: 'payments.data.providercodedescription',
          value: payment_provider_info.paymentProviderMessage,
        },
        {
          key: 'payments.data.reasonCode',
          value: payment_provider_info.mappedPaymentProviderCode,
        },
        {
          key: 'payments.data.reasonCodeDescription',
          value: payment_provider_info.mappedPaymentProviderMessage,
        },
        {
          key: 'payments.data.ARN',
          value: payment_provider_info.arn,
        },
        {
          key: 'payments.data.RRN',
          value: payment_provider_info.rrn,
        },
      ],

      paymentMethodInfo: [
        {
          key: 'payments.data.paymentMethod',
          value: operationData.payment_method_group,
        },
        {
          key: 'payments.data.paymentMethodType',
          value:
            !iconUrl && !payment_method_type
              ? undefined
              : { iconUrl, payment_method_type },
          type: types.image,
        },
        {
          key: 'payments.data.accountNumber',
          value: operationData.account_number,
        },
        {
          key: 'payments.data.cardHolder',
          value: operationData.card_holder,
        },
        {
          key: 'payments.data.cardProductName',
          value: operationData.account_funding_source,
        },
        {
          key: 'payments.data.cardExpiry',
          value: operationData.card_expiration_date,
        },
        {
          key: 'payments.data.bankName',
          value: operationData.issuer_bank_name,
        },
        {
          key: 'payments.data.countryByCardBIN',
          value: operationData.issuer_country,
        },
        {
          key: 'payments.data.cardProductType',
          value: operationData.customer_card_product_type,
        },
        { key: 'payments.data.BIN', value: operationData.bin },
      ],

      deviceInfo: [
        {
          key: 'payments.data.browser',
          value: operationData.browser,
        },
        {
          key: 'payments.data.browserVersion',
          value: operationData.browser_version,
        },
        {
          key: 'payments.data.device',
          value: operationData.device,
        },
        {
          key: 'payments.data.deviceFamily',
          value: operationData.device_family,
        },
        {
          key: 'payments.data.displayResolution',
          value: operationData.display_resolution,
        },
        {
          key: 'payments.data.operatingSystem',
          value: operationData.operating_system,
        },
        {
          key: 'payments.data.operatingSystemVersion',
          value: operationData.operating_system_version,
        },
      ],

      callback: [
        { key: 'payments.data.callbackstatus', value: callback.callbackStatus },
        {
          key: 'payments.data.callbackCreationdate',
          value: callback.callbackCreationDate,
        },
        { key: 'payments.data.attempts', value: callback.callbackAttempts },
        {
          key: 'payments.data.lastattempts',
          value: callback.callbackLastAttempt,
        },
      ],

      endpoint: [
        {
          key: 'payments.data.EndpointpaymentproviderID',
          value: payment_provider_info.endpointPaymentProviderId,
        },
        {
          key: 'payments.data.EndpointID',
          value: payment_provider_info.endpointId,
        },
      ],

      secure: [
        {
          key: 'payments.data.3DecureCheck',
          value: operationData['3ds_secure']['3dsSecure'],
        },
        {
          key: 'payments.data.enrollment',
          value: operationData['3ds_secure']['enrollmentStatus'],
        },
        {
          key: 'payments.data.paResStatus',
          value: operationData['3ds_secure']['pares_status'],
        },
        {
          key: 'payments.data.paResECI/SLI',
          value: operationData['3ds_secure']['eci'],
        },

        {
          key: 'payments.data.authFlow',
          value: operationData['3ds_secure']['authentication_flow'],
        },

        {
          key: 'payments.data.3dsVersion',
          value: operationData['3ds_secure']['version_3ds'],
        },
        {
          key: 'payments.data.non3dsReason',
          value: operationData['3ds_secure']['non_3ds_reason'],
        },
      ],

      address: {
        isVisible:
          Boolean(operationData.avs_check) &&
          operationData.avs_check !== 'False',
        content: [
          {
            key: 'payments.data.AVSCheck',
            value: operationData.avs_check,
          },
          // {
          //   key: 'payments.data.AVSCode',
          //   value: null,
          // },
          {
            key: 'payments.data.AVSCodeDetails',
            value: operationData.avs_code_details,
          },
          {
            key: 'payments.data.AVSPostCode',
            value: operationData.avs_post_code,
          },
          {
            key: 'payments.data.AVSAddress',
            value: operationData.avs_street_address,
          },
        ],
      },

      fraud: {
        isFraud: Boolean(operationData.fraud),
        content: [
          {
            key: 'payments.data.fraudReportDate',
            value: operationData.fraudReport?.fraudReportDate,
          },
          {
            key: 'payments.data.fraudType',
            value: operationData.fraudReport?.fraudTypeTitle,
          },
          {
            key: 'payments.data.fraudTypeDefinition',
            value: operationData.fraudReport?.fraudTypeDefinition,
          },
        ],
      },

      cryptoInfo: {
        isVisible: !(
          operationData.crypto_amount === null ||
          operationData.crypto_amount === undefined ||
          operationData.crypto_amount === ''
        ),
        content: [
          {
            key: 'payments.data.cryptoExchangeRate',
            value: operationData.crypto_exchange_rate,
          },
          {
            key: 'payments.data.cryptoAmount',
            value: operationData.crypto_amount,
          },
          {
            key: 'payments.data.cryptoCurrency',
            value: operationData.crypto_currency,
          },
          {
            key: 'payments.data.blockchainUrl',
            type: types.link,
            value: {
              url: operationData.blockchain_url,
              name: operationData.blockchain_url,
            },
          },
        ],
      },
      vatBillingInfo: {
        isVisible: operationData.person_type === 'Company',
        content: [
          {
            key: 'payments.data.country',
            value: operationData.billing_info.billingCountry,
          },
          {
            key: 'payments.data.city',
            value: operationData.billing_info.billingCity,
          },
          {
            key: 'payments.data.address',
            value: operationData.billing_info.billingAddress,
          },
          {
            key: 'payments.data.postalCode',
            value: operationData.billing_info.billingPostal,
          },
          {
            key: 'payments.data.regionCode',
            value: operationData.billing_info.billingRegionCode,
            isVisible: (operationData.billing_info.billingCountry === 'US' || operationData.billing_info.billingCountry === 'CA')
          },
          {
            key: 'payments.data.vatNumber',
            value: operationData.vat_number,
          },
          {
            key: 'payments.data.companyName',
            value: operationData.billing_company_name,
          },
        ],
      },
    };
  }

  static getPaymentInfo(paymentData) {
    const {
      payment,
      customer_info,
      payment_method,
      billing_info,
      lastCallback: callback,
      remittanceRecipientAccount,
    } = paymentData;

    const { iconUrl, paymentMethodType } = payment_method || {};

    return {
      paymentInfo: [
        { key: 'payments.data.paymentID', value: payment.paymentId },
        { key: 'payments.data.token', value: payment.card_token },
        { key: 'payments.data.paymentType', value: payment.paymentType },
        {
          key: 'payments.data.paymentMethod',
          value: payment.paymentMethodGroup,
        },
        {
          key: 'payments.data.paymentMethodType',
          value:
            !iconUrl && !paymentMethodType
              ? undefined
              : { iconUrl, paymentMethodType },
          type: types.image,
        },
        {
          key: 'payments.data.accountNumber',
          value: payment_method.paymentAccount,
        },
        {
          key: 'payments.data.cardProductType',
          value: payment_method.customer_card_product_type,
        },
        {
          key: 'payments.data.amount',
          value: `${payment.paymentAmount}\u00A0${payment.paymentCurrency}`,
        },
        {
          key: 'payments.data.channelAmount',
          value: `${payment.channelAmount}\u00A0${payment.channelCurrency}`,
        },
        // { key: 'payments.data.paymentFee', value: null }, //DE-350 - убрали пока нет обсчета
        {
          key: 'payments.data.paymentStatus',
          value: payment.paymentStatus,
          type: types.status,
        },
        { key: 'payments.data.created', value: payment.creationDate },
        { key: 'payments.data.updated', value: payment.updatedDate },
        {
          key: 'payments.data.description',
          value: payment.payment_description,
        },
        { key: 'payments.data.COF', value: payment.recurring },
      ],

      projectInfo: [
        { key: 'payments.data.projectID', value: payment.projectId },
        { key: 'payments.data.projectName', value: payment.projectName },
        { key: 'payments.data.projectURL', value: payment.projectUrl },
        { key: 'payments.data.brand', value: payment.company_name },
      ],

      customerInfo: [
        { key: 'payments.data.customer_id', value: customer_info.customerId },
        {
          key: 'payments.data.customer_name',
          value:
            customer_info.customerFirstName +
            ' ' +
            customer_info.customerLastName,
        },
        { key: 'payments.data.dateofbirth', value: customer_info.customerDOB },
      ],

      customerIp: [
        { key: 'payments.data.customerIP', value: customer_info.customerIp },
        { key: 'payments.data.countryByIP', value: customer_info.countryByIp },
      ],

      contactInfo: [
        { key: 'payments.data.email', value: customer_info.customerEmail },
        { key: 'payments.data.phone', value: customer_info.customerPhone },
        { key: 'payments.data.city', value: customer_info.customerCity },
        { key: 'payments.data.country', value: customer_info.customerCountry },
      ],

      billingInfo: [
        {
          key: 'payments.data.billingAddress',
          value: billing_info.billingAddress,
        },
        { key: 'payments.data.billingCity', value: billing_info.billingCity },
        {
          key: 'payments.data.billingRegion',
          value: billing_info.billingRegion,
        },
        {
          key: 'payments.data.BillingCountry',
          value: billing_info.billingCountry,
        },
        {
          key: 'payments.data.BillingPostalCode',
          value: billing_info.billingPostal,
        },
      ],

      callback: callback
        ? [
            {
              key: 'payments.data.operationType',
              value: callback.operationType || null,
            },
            {
              key: 'payments.data.operationStatus',
              value: callback.operationStatus || null,
            },
            {
              key: 'payments.data.creationtime',
              value: callback.callbackCreationDate,
            },
            { key: 'payments.data.attempts', value: callback.callbackAttempts },
            {
              key: 'payments.data.lastattempts',
              value: callback.callbackLastAttempt,
            },
          ]
        : [],

      remittanceRecipientAccount: remittanceRecipientAccount
        ? [
            {
              key: 'remittance.payments.RecipAccInfo.recipAccID',
              value: remittanceRecipientAccount.remittanceRecipientAccountId,
            },
            {
              key: 'remittance.payments.RecipAccInfo.title',
              value: remittanceRecipientAccount.title,
            },
            {
              key: 'remittance.payments.RecipAccInfo.beneficName',
              value: remittanceRecipientAccount.beneficiaryName,
            },
            {
              key: 'remittance.payments.RecipAccInfo.bankName',
              value: remittanceRecipientAccount.beneficiaryBankName,
            },
          ]
        : [],
    };
  }
}

export default PaymentDataMapper;
